<template>
  <div class="row justify-content-center align-items-md-center">
    <div class="col-md-12">
      <div
        class="card full-height bg-ligth mb-4 shadow-lg p-3 mb-5 bg-white rounded"
      >
        <div class="card-header d-flex justify-content-between">
          <span
            class="label label-lg label-inline px-2 pb-2 pt-3 col-3"
            :class="{
              'label-light-info': lista_mensagens[0].tipo === 1,
              'label-light-primary': lista_mensagens[0].tipo === 0,
            }"
          >
            {{
              lista_mensagens[0].tipo === 1
                ? "Técnico/Sistema"
                : "Financeiro/administração"
            }}
          </span>
          <span
            class="label label-lg label-inline px-2 pb-2 pt-3"
            :class="{
              'label-light-success':  lista_mensagens[0].status === 1,
              'label-light-primary':  lista_mensagens[0].status === 2,
            }"
          >
            {{    lista_mensagens[0].status === 1 ? "fechado" :   lista_mensagens[0].status === 2 ? "aberto" : ""}}
        

          </span>
          <span
            class="label label-lg label-inline px-2 pb-2 pt-3 col-3"
            :class="{
              'label-light-success': lista_mensagens[0].prioridade === 1,
              'label-light-primary': lista_mensagens[0].prioridade === 2,
            }"
          >
            {{
              lista_mensagens[0].prioridade === 1
                ? "alta"
                : lista_mensagens[0].prioridade === 2
                ? "média"
                : "baixa"
            }}
          </span>
        </div>
        <div class="card-header d-flex justify-content-between">
          <span>
            Assunto:
            <span style="font-weight: 700; text-transform: capitalize">{{
              lista_mensagens[0].assunto
            }}</span></span
          >

          <span style="font-size: 12px">{{
            lista_mensagens[0].created_at | formatDate
          }}</span>
        </div>
      </div>

      <div class="row" style="margin-top: -1%; margin-bottom: -3%;" v-for="lista in lista_mensagens" :key="lista.id">
        <div v-if="lista.usuario_id == usuario_id" class="col-md-6"></div>
        <div class="col-md-6">
          <div
            class="card full-height bg-ligth mb-4 shadow-lg p-3 mb-5 bg-white rounded"
          >
            <div class="card-body">
              <div class="d-flex justify-content-between text-uppercase mb-1">
                <h6>{{ lista.nome_sobrenome }}</h6>
                <h6>{{ lista.created_at | formatDate }}</h6>
              </div>

              {{ lista.mensagem }}
            </div>
          </div>
        </div>
        <div v-if="lista.usuario_id != usuario_id" class="col-md-6"></div>
      </div>
      <div v-if="lista_mensagens[0].status != 1" class="row ">
      
      <div class="col-md-11">
        <textarea
          required
          rows="2"
          type="text"
          class="form-control  bg-ligth"
       
          v-model="form.mensagem"
          placeholder="Digite o mensagem..."
        />
        </div>
      <div class="col-md-1">

        <button
               style="height: 49px;width: 100%;"
@click="confirm('cria')"
                  class="btn btn-primary "
                  :disabled="verif"
                >
                
                  <i class="fas fa-paper-plane"></i>
                  <b-spinner
                    v-show="verif"
                    small
                    variant="dark"
                    type="grow"
                    label="Spinning"
                  ></b-spinner>
                </button>
                </div>
      </div>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/store/breadcrumbs.module";
import dateFormat from "@/core/mixins/dateFormat";
import UsuarioService from "@/core/services/usuario.service";
import { fireAlert } from "@/core/mixins/alertMessageMixin";

export default {
  mixins: [dateFormat,fireAlert],
  data() {
    return {
      currentPage: 1,
      perPage: 10,
      usuario_id: UsuarioService.getUsuario()["usuario_id"],
      form: { mensagem: null,
        chamados_id: null,usuario_id:null },
        verif:false
    };
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Chamado Mensagens" }]);
  },
  created() {
    this.listar_tipos_aviso();
  },
  computed: {
    lista_mensagens() {
      return this.$store.state.chamados.lista_mensagens;
    },
    lista_permissoes_filial() {
      return this.$store.state.sistema.lista_permissoes_filial;
    },
    mensagem_alert() {
      return this.$store.state.chamados.mensagem_alert;
    },
  },
  methods: {

    async confirm(tipo) {
      await this.$confirm({
        title: "Tem certeza?",
        message: `Esta ação  ` + tipo + ` uma messagem para esse chamado?`,
        button: {
          no: "No",
          yes: "Si",
        },
        callback: (confirm) => {
          if (confirm) {
            if (tipo == "cria") this.create();
            // if (tipo == "edita") this.update();
          }
        },
      });
    },
    async create() {
      this.verif = true;
      this.form.chamados_id = this.$route.params.chamado_id
      this.form.usuario_id = this.usuario_id

      await this.$store.dispatch("chamados/criar_mensagens", this.form);
      this.verif = false;
      this.fireAlert({
        ...this.mensagem_alert,

      });
      // this.listar_tipos_aviso()
      this.form.mensagem = ''
    },
    async listar_tipos_aviso() {
      this.$store
        .dispatch("chamados/listar_mensagens", this.$route.params.chamado_id)
  while(true){
 
       
  await this.sleep(15000)
   this.$store
        .dispatch("chamados/listar_mensagens", this.$route.params.chamado_id)
   
       
      }
    },
    atualizar(value) {
      this.$router.push({ name: "createTipoProduto" });
      this.$store.dispatch("configEmpresa/atualizar", value);
    },
     sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
}

 
  
  },
};
</script>

<style scoped>
.lista:hover {
  background-color: rgba(167, 183, 255, 0.705);
}
</style>